.fade-in-text {
    display: inline-block;
    animation: fadeIn linear 3s;
    -webkit-animation: fadeIn linear 3s;
    -moz-animation: fadeIn linear 3s;
    -o-animation: fadeIn linear 3s;
    -ms-animation: fadeIn linear 3s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
