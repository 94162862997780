/*root*/
#root {
    height: 100%;
    width: 100%;
}


/* Navigation Bar*/
.AppBar {
    background-color: white;
    border-bottom: 1px solid #707070;
}

.hover-fund:hover {
    color: #1967d2;
    background-color: #F8F9FA;
}

/*Control Panel*/
.ControlPanelText {
    font-weight: 400;
    font-size: 20px;
}
.ControlPanelPaper {
    height: 100%;
    width: 100%;
    overflow: auto;
}
.ControlPanelTitle {
    border-bottom: 1px solid #dadce0;

}
.ControlPanelSubtitle {
    height: 80px;
    border-bottom: 1px solid #dadce0;
    font-size: 14px;
}
.SummaryHeader {
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 2px solid #EDEDED;
}
.SummaryDescription {
    margin-left: 10px;
    font-size: 15px;
}

.SummaryDivider {
    border-bottom: 2px solid #AFAFAF;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.SummaryYourIndex {
    margin-left: 10px;
    font-size: 16px;
    margin-top: 50px;
}

.SummaryTitle {
    margin-left: 10px;
    font-size: 24px;
    margin-bottom: 0px;
}

.SummarySave {
    color: #356AE7;
    margin-left: 5px;
    font-size: 13px;
}

/*Builder Button*/
.BuilderButton {
    justify-content: center;
    display: flex;
    margin: 10px;
}

/*Expansion Panel*/
.ExpansionPanel {
    overflow: auto;
    border-bottom: 1px solid #dadce0;
    width: 100%;
    margin: 0;
}
.ExpansionDetails {
    background-color: #F8F9FA;
    width: 100%;
}
.Mui-expanded.expanded {
    background-color: #F8F9FA;
}


/*Footer*/
.CostAmount {
    font-weight: 600;
    font-size: 2.5em;
}
.CostTitle {
    font-weight: 300;
    font-size: 1.4em;
}


/*Page Composition*/
.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px minmax(70px, auto) minmax(150px, auto) minmax(550px, auto) minmax(600px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Nav-Bar Nav-Bar"
    "Title Control-Panel"
    "Metrics Control-Panel"
    "Sector-vs-Benchmark Control-Panel"
    "Top-10-Holdings Control-Panel";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.containerTaxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px minmax(400px, auto) minmax(400px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Nav-Bar Nav-Bar"
    "Tax-Image1 Control-Panel"
    "Tax-Image2 Control-Panel";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.Tax-Image1 { grid-area: Tax-Image1}
.Tax-Image2 { grid-area: Tax-Image2}

.containerValues {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px minmax(1fr,auto) minmax(1fr,auto) minmax(1fr,auto) minmax(1fr,auto) minmax(1fr,auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Nav-Bar"
    "Pre-Configured-Values"
    "Custom-Options"
    "Custom-Exclusions"
    "Revenue-Threshold-Exclusions"
    "Company-Industry-Exclusions";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.Pre-Configured-Values { grid-area: Pre-Configured-Values}
.Custom-Options { grid-area: Custom-Options}
.Custom-Exclusions { grid-area: Custom-Exclusions}
.Revenue-Threshold-Exclusions { grid-area: Revenue-Threshold-Exclusions}
.Company-Industry-Exclusions { grid-area: Company-Industry-Exclusions}

.containerSummary{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px 70px;
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Nav-Bar Nav-Bar"
    "Title Control-Panel"
    "Summary Control-Panel";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.Nav-Bar { grid-area: Nav-Bar; }
.Title { grid-area: Title}
.Control-Panel { grid-area: Control-Panel;}
.Metrics { grid-area: Metrics;}
.Top-10-Holdings { grid-area: Top-10-Holdings;}
.Sector-vs-Benchmark { grid-area: Sector-vs-Benchmark; padding-top: 20px; }

.D3 { grid-area: D3; display: flex;
    justify-content: center; padding-top: 10px;}


#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

html, body {
    height: 100%;
    margin: 0;
}

.wrapper {
    position: relative;
    background-color: black;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
}
.content p{
    display: block;
}

.overlay {
    opacity: 0;
    transition: .5s ease;

}

.wrapper:hover img{
    opacity: .3;
}

.wrapper:hover .overlay {
    opacity: 1;
}

.ReportCardsContainer {
    display: grid;
    grid-template-columns: 250px 200px 100px;
    grid-template-rows: 200px 200px;
    gap: 0px 0px;
    width: 100%;
    height: 100%;
}

.arrow-pulse-down:after {
    content: "🡣";
    width: 35px; height: 35px;
    margin-left: 20px;
    font-size: 35px;
    color: #1967d2;
    animation: arrow-pulse-down 1200ms linear infinite;
}
@keyframes arrow-pulse-down {
    0% {
        bottom: 40px;
        opacity: 0;
    }
    25% {
        opacity: .25;
    }
    50% {
        opacity: .5;
    }
    75% {
        opacity: .75;
    }
    100% {
        bottom: 20px;
        opacity: 1;
    }
}

.circle {
    border-radius: 50%;
    background-color: #1967d2;
    width: 40px;
    height: 40px;
    position: relative;
    margin-top: -42px;
    margin-left: 15px;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}

@keyframes scaleIn {
    from {
        transform: scale(.5, .5);
        opacity: .15;
    }
    to {
            transform: scale(2.5, 2.5);
            opacity: 0;
    }
}

.customFontText {
    font-family: "proxima-nova", sans-serif;
}