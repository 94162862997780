.AddAccountContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(350px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "AddAccount";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.AddAccount { grid-area: AddAccount; }

html, body {
    height: 100%;
    margin: 0;
}
