.AccountSettingsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(20px, auto) minmax(10px, auto) minmax(100px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Title"
    "Tabs"
    "Main";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.Title { grid-area: Title; }
.Tabs { grid-area: Tabs; }
.Main { grid-area: Main; }

html, body {
    height: 100%;
    margin: 0;
}
