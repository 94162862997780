/*root*/
#root {
    height: 100%;
    width: 100%;
}

.DashContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(450px, auto) minmax(500px, auto) minmax(587px, auto) minmax(352px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Onboarding Earnings"
    "Assets Assets"
    "Top-Accounts Top-Portfolios"
    "Participation Compliance-Status";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}
.Onboarding { grid-area: Onboarding; }

.News { grid-area: News; }

.Earnings { grid-area: Earnings; }

.Assets { grid-area: Assets; }

.Notifications { grid-area: Notifications; }

.Top-Accounts { grid-area: Top-Accounts; }

.Top-Portfolios { grid-area: Top-Portfolios; }

.Participation { grid-area: Participation; }

.Compliance-Status { grid-area: Compliance-Status; }


html, body {
    height: 100%;
    margin: 0;
}

