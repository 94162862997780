.ClientsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(300px, auto) 1fr;
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Participation Compliance-Status"
    "Accounts Accounts";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}


.Accounts { grid-area: Accounts; }

.Participation { grid-area: Participation; }

.Compliance-Status { grid-area: Compliance-Status; }


html, body {
    height: 100%;
    margin: 0;
}


.AccountInfoLiveContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(30px, auto) minmax(125px, auto) minmax(587px, auto) minmax(587px, auto) minmax(587px, auto) minmax(587px, auto) minmax(252px, auto) minmax(30px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Account-Title Account-Title"
    "Account-Overview Account-Overview"
    "Client-Preferences Account-History"
    "Holdings Holdings"
    "Portfolio Portfolio"
    "Activity Activity"
    "Impact-Summary Impact-Summary";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}
.Account-Title { grid-area: Account-Title;}
.Account-Overview { grid-area: Account-Overview; }
.Client-Preferences { grid-area: Client-Preferences; }
.Account-History { grid-area: Account-History; }
.Current-Allocations { grid-area: Current-Allocations; }
.Activity { grid-area: Activity; }
.Portfolio { grid-area: Portfolio; }
.Impact-Summary { grid-area: Impact-Summary; }
.Impact-Graph { grid-area: Impact-Graph; }


.AccountTransitionContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(30px, auto) minmax(540px, auto) minmax(240px, auto) minmax(240px, auto)
    minmax(587px, auto) minmax(520px, auto) minmax(587px, auto) minmax(587px, auto) minmax(30px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Account-Title Account-Title"
    "Client-Preferences Account-Onboarding"
    "Portfolio Portfolio"
    "Transition Transition"
    "Holdings Sectors"
    "ESG-Laggards ESG-Laggards"
    "Impact-Summary Impact-Summary"
    "Exclusions Exclusions";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}
.Account-Title { grid-area: Account-Title;}
.Account-Onboarding { grid-area: Account-Onboarding; }
.Portfolio { grid-area: Portfolio; }
.Transition { grid-area: Transition; }
.Holdings { grid-area: Holdings; }
.Sectors { grid-area: Sectors; }
.ESG-Laggards { grid-area: ESG-Laggards; }
.Exclusions { grid-area: Exclusions; }

.AccountOnboardingContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(30px, auto) minmax(540px, auto) minmax(240px, auto) minmax(30px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Account-Title Account-Title"
    "Client-Preferences Account-Onboarding"
    "Portfolio Portfolio";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}