.StrategiesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(200px, auto) minmax(600px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Custom-Strategies Custom-Strategies"
    "All-Strategies All-Strategies";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.All-Strategies { grid-area: All-Strategies; }
.Custom-Strategies { grid-area: Custom-Strategies; }



html, body {
    height: 100%;
    margin: 0;
}


.StrategiesDescriptionContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(30px, auto) minmax(135px, auto) minmax(135px, auto) minmax(540px, auto) minmax(455px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Strategy-Title Strategy-Title"
    "Strategy-Description Strategy-Description"
    "Strategy-Overview Strategy-Overview"
    "Holdings Sector-Breakdown"
    "Performance Performance"
    "Performance-Monthly Performance-Monthly";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}
.Strategy-Title { grid-area: Strategy-Title; }
.Strategy-Description { grid-area: Strategy-Description; }
.Strategy-Overview { grid-area: Strategy-Overview; }
.Holdings { grid-area: Holdings; }
.Sector-Breakdown { grid-area: Sector-Breakdown; }
.Impact-Summary { grid-area: Impact-Summary; }
.Performance { grid-area: Performance; }
.Performance-Monthly { grid-area: Performance-Monthly; }

.CustomStrategiesDescriptionContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(30px, auto) minmax(135px, auto) minmax(135px, auto) minmax(540px, auto) minmax(455px, auto);
    grid-auto-columns: 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
    grid-template-areas:
    "Strategy-Title Strategy-Title"
    "Strategy-Overview Strategy-Overview"
    "Strategy-Description Strategy-Description"
    "Holdings Sector-Breakdown"
    "Impact-Summary Impact-Summary"
    "Performance Performance";
    width: 100%;
    height: 100%;
    margin-top: 100px;
}
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #488cea;
    color: #488cea;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
    float: right;
    margin-right: 15px;
}

.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #488cea;
    color: #488cea;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #488cea;
    color: #488cea;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: #488cea;
    }
    50%,
    100% {
        background-color: #c8dcf9;
    }
}


/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
.dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
    0% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }
    16.667% {
        box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }
    33.333% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }
    50% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }
    66.667% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }
    83.333% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px -10px 0 0 #9880ff;
    }
    100% {
        box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    }
}

